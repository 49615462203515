import template from './BatchView.html';

class BatchViewPageViewModel
{
	constructor (page)
	{
		this.page = page;
		this.loading = ko.observable(false);
		this.batch_id = ko.observable();
		this.batch = ko.observable();
		this.batch_state = ko.observable('');
		this.edited_cells = ko.observableArray([]);
		this.batch_status_timeout = null;

		this.initialize_grid();
	}

	initialize_grid ()
	{
		let input_element = document.body.getElementsByClassName('data-cells')[0];

		this.grid = document.createElement('canvas-datagrid');	  
		this.grid.style.height = '50vh';
		this.grid.style.width = '100%';

		input_element.appendChild(this.grid);

		this.grid.addEventListener('beginedit', (e) => {
			e.cell._oldValue = e.cell.value;
		});

		this.grid.addEventListener('endedit', (e) => {
			if (e.cell._oldValue == e.value || (e.cell._oldValue === null && e.value === ''))
				return;
			else
			{
				let row_data = this.grid.data[e.cell.boundRowIndex];

				if (!row_data._edited_cells)
					row_data._edited_cells = {};
	
				row_data._edited_cells[e.cell.header.name] = {
					boundRowIndex: e.cell.boundRowIndex,
					boundColumnIndex: e.cell.boundColumnIndex,
					oldValue: e.cell.formattedValue,
					newValue: e.value
				};

				this.grid.draw();
			}
		});

		this.grid.addEventListener('contextmenu', (e) => {
			if (e.cell && !e.cell.isRowHeader)
			{
				e.items.push({
					title: 'Clear Column',
					click: async () => {
						let response = await Grape.alerts.confirm({ type: 'danger', message: 'Are you sure you want to clear the ENTIRE column?' });
						if (response)
						{
							let col_index = e.cell.boundColumnIndex;
							let header = this.grid.schema[col_index];
			
							for (let row_index = 0; row_index < this.grid.data.length; row_index++)
							{
								let row = this.grid.data[row_index];
								
								if (row[header.name] !== "")
								{
									if (!row._edited_cells)
										row._edited_cells = {};
			
									row._edited_cells[header.name] = {
										boundRowIndex: row_index,
										boundColumnIndex: col_index,
										oldValue: row[header.name],
										newValue: ""
									};
			
									row[header.name] = "";
								}
							}
							this.grid.draw();
						}
					}
				});
			}
		});

 		this.grid.addEventListener('rendercell', (e) => {
			if (e.cell.boundRowIndex > -1 && this.grid.data && this.grid.schema)
			{
				let row_data = this.grid.data[e.cell.boundRowIndex];

				// Check if cell is row header.
				if (e.cell.isRowHeader)
				{
					// If row has any error messages, combine and mark the header red
					if (row_data._error_messages && Array.isArray(row_data._error_messages) && row_data._error_messages.length > 0)
					{
						e.cell.title = row_data._error_messages.filter(err => err.field && err.error).map(err => `${err.field}: ${err.error}`).join(', ');
						e.ctx.fillStyle = 'red';
						e.ctx.fillRect(e.cell.x, e.cell.y, e.cell.width, e.cell.height);
					}
				}
				else
				{
					// For regular cells, check for errors
					if (row_data._error_messages && Array.isArray(row_data._error_messages))
					{
						let field_errors = row_data._error_messages.filter(err => err.field === e.cell.header.name);
						if (field_errors.length > 0)
						{
							e.cell.title = field_errors.map(err => err.error).join(', ');
							e.ctx.fillStyle = 'red';
							e.ctx.fillRect(e.cell.x, e.cell.y, e.cell.width, e.cell.height);
						}
					}
				
					// Styling for empty cells
					if (e.cell.value === '' || e.cell.value === null || e.cell.value === undefined)
					{
						e.ctx.strokeStyle = 'orange';
						e.ctx.lineWidth = 2;
						e.ctx.strokeRect(e.cell.x, e.cell.y, e.cell.width, e.cell.height);
					}
				
					// Styling for edited cells
					if (row_data && row_data._edited_cells && row_data._edited_cells[e.cell.header.name])
					{
						e.ctx.fillStyle = 'yellow';
						e.ctx.fillRect(e.cell.x, e.cell.y, e.cell.width, e.cell.height);
					}
				}
			}
		});
		
		this.grid.addEventListener('mousemove', (e) => {
			if (e.cell && e.cell.title && e.cell.title.trim() !== "")
				document.querySelector('.validation-error').innerText = e.cell.title;
		});

	}

	async btn_save_batch_edits_clicked ()
	{
		let changes = await this.collect_batch_edits();

		if (!changes.length)
		{
			Grape.alerts.alert({  type: 'info', message: 'You have not edited any values!' });
			return;
		}

		let record_upd_promises = changes.map((update) => {
			return Grape.fetches.postJSON('/api/etl/record/update', update);
		});

		try
		{
			let results = await Promise.all(record_upd_promises);
			let errors = results.filter((res) => res.status !== 'OK');
	
			if (errors.length)
				Grape.alerts.alert({ type: 'warning', message: 'Some updates failed!' });
			else
			{
				this.update_batch_records();
				Grape.alerts.alert({ type: 'success', message: 'Updated successfully!' });
			}
	
		} catch (error) {
			console.error("Error updating records", error);
			Grape.alerts.alert({ type: 'error', message: 'Error updating records.' });
		}
	
		this.clear_batch_edit_flags();
	}

	async btn_clear_batch_edits_clicked ()
	{
		let response = await Grape.alerts.confirm({ type: 'warning', message: 'Are you sure you want to cancel and restore all changes to this batch?' });
		if (response)
		{
			this.clear_batch_edit_flags();
			this.update_batch_records();
		}
	}

	collect_batch_edits ()
	{
		let changes = [];
	
		for (let i = 0; i < this.grid.data.length; i++)
		{
			let row = this.grid.data[i];
			if (row._edited_cells)
			{
				changes.push({
					record_id: row._record_id,
					batch_row_nr: i,
					extracted_data: this.clean_row_data(row)
				});
			}
		}
		return changes;
	}

	clean_row_data (row)
	{
		let clean = {};
		for (let key in row)
			if (row.hasOwnProperty(key) && key.charAt(0) !== '_')
				clean[key] = row[key];
		return clean;
	}

	clear_batch_edit_flags ()
	{
		this.grid.data.forEach(row => {
			if (row._edited_cells)
				delete row._edited_cells;
		});

		this.grid.draw();
	}

	async btn_process_batch_click ()
	{
		try
		{
			let result = await Grape.fetches.postJSON('/api/etl/batch/start-process', {batch_id: parseInt(this.batch_id())});

			if (result.status === 'OK')
			{
				Grape.alerts.alert({ type: 'info', message: 'Batch processing started!' });
				this.check_batch_status();
			}
			
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async check_batch_status ()
	{
		clearTimeout(this.batch_status_timeout);
	
		try
		{
			await this.update_batch();
			let state = this.batch_state();
		
			if (state === 'Processing')
			{
				this.batch_status_timeout = setTimeout(() => {
					this.check_batch_status();
				}, 1000);
			}
			else if (state === 'Completed')
			{
				Grape.alerts.alert({ type: 'success', message: 'Batch Processing Completed!' });
				await this.update_batch();
			}
		} catch (error) {
			console.error("Error updating batch during polling", error);
		}
	}
	
	async update_batch ()
	{
		let result = await Grape.fetches.getJSON('/api/record', {
			table: 'v_batches',
			schema: 'etl',
			filter: [{field: 'batch_id', value: this.batch_id(), operand: '='}]
		});

		if (result.status == 'OK' && result.records.length == 1)
		{
			this.batch(result.records[0]);
			this.batch_state(this.batch().state);
			this.update_batch_records();
		}
		else
			throw new Error(result.message || result.code);
	}

	async update_batch_records ()
	{
		let result = await Grape.fetches.getJSON('/api/record', {
			table: 'v_batch_records',
			schema: 'etl',
			limit: 100000,
			filter: [{field: 'batch_id', operator: '=', value: this.batch_id()}]
		});

		if (result.status == 'OK')
		{
			let grid_data = result.records.map(record => {
				let data_field = record.extracted_data;

				if (typeof data_field === 'string')
					data_field = JSON.parse(data_field);
		
				data_field._record_id = record.record_id;
				data_field._error_messages = record.error_messages;
				return data_field;
			});
		
			this.grid.data = grid_data;
		}
		else
			throw new Error(result.message || result.code);

		if (this.grid.data && this.grid.data.length)
		{
			this.grid.schema = Object.keys(this.grid.data[0]).filter(key => key.charAt(0) !== '_').map(key => ({
				name: key,
				title: key
			}));
		}

		this.grid.draw();
	}

	btnBatches_click ()
	{
		Grape.navigate(`/etl-ui/batch-list`);
	}

	btn_workbook_list_click ()
	{
		Grape.navigate(`etl-ui/workbook-list`);
	}
}

class BatchViewPageClass
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.viewModel = new BatchViewPageViewModel(this);
		this.name = 'BatchViewPageClass';

		this.viewModel.batch_id(bindings.batch_id);
	}

	init ()
	{
		document.title = 'Batch';
	}

	async updateData ()
	{
		this.viewModel.loading(true);

		if (this.viewModel.batch_id() == null || this.viewModel.batch_id() == 'null')
		{
			Grape.navigate(`etl-ui/batch-list`);
			Grape.alerts.alert({ type: 'info', message: 'Could not find the batch! It might still be processing!' });
			return;
		}

		await this.viewModel.update_batch();

		if (this.bindings.auto_process_batch)
			this.viewModel.btn_process_batch_click();

		this.viewModel.loading(false);
	}
}

export default {
	route: '[/]etl-ui/batch-view',
	page_class: BatchViewPageClass,
	template: template
};
