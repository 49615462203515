import template from './WorkbookView.html';

class WorkbooksViewPageViewModel
{
	constructor(page)
	{
		this.page = page;
		this.el = page.element;
		this.loading = ko.observable(false);
		this.process_to_batch_clicked = ko.observable(false);
		this.workbook_id = ko.observable();
		this.batch_id = ko.observable(null);
		this.worksheet_id = ko.observable();
		this.records = ko.observableArray([]);
		this.worksheets = ko.observableArray([]);
		this.worksheet = ko.observable();
		this.worksheet_status = ko.observable('Unknown');
		this.workbook = ko.observable();
		this.auto_process_batch = ko.observable(true);

		this.selected_mapping_type = ko.observable('heading-is-names');
		this.available_maps = ko.observableArray([]);
		this.selected_map = ko.observable();
		this.available_pipelines = ko.observableArray();
		this.selected_pipeline = ko.observable();
		this.ui_params = ko.observableArray([]);
		this.ui_param_values = ko.observable({});
		this.pipeline_steps = ko.observableArray([]);
		this.refresh_timeout = null;
		this.refresh_workbook_timeout = null;
		this.refresh_timeout_duration = 1000;
		this.prev_worksheet_status = null;

		this.edited_cells = ko.observableArray([]);
		this.deleted_rows = ko.observableArray([]);

		this.selected_pipeline.subscribe((pipeline) => {
			if (!pipeline)
			{
				this.pipeline_steps([]);
				return;
			}

			let ui_params = [];
			pipeline.transformers.forEach((trans) => {
				if (trans.ui_param !== null)
					ui_params.push(...trans.ui_param);
			});

			this.ui_params(ui_params);

			let steps = [];
			pipeline.transformers.forEach((trans) => {
				if (trans.active)
				{
					trans.selected = true;
					steps.push(trans);
				}
			});

			this.pipeline_steps(steps);
		});

		this.worksheet_id.subscribe(() => {
			this.update_sheet();
			this.prev_worksheet_status = null;
		});

		this.selected_mapping_type.subscribe((newMapping) => {
			this.update_cells();
		});
		
		this.initialize_grid();
	}

	initialize_grid ()
	{
		let input_element = document.body.getElementsByClassName('data-cells')[0];

		this.grid = document.createElement('canvas-datagrid');	  
		this.grid.style.height = '50vh';
		this.grid.style.width = '100%';
		this.grid.editable = (this.worksheet_status() === 'Unbatched');

		input_element.appendChild(this.grid);

		this.grid.addEventListener('beginedit', (e) => {
			e.cell._oldValue = e.cell.value;
		});

		this.grid.addEventListener('endedit', (e) => {
			if (e.cell._oldValue == e.value || (e.cell._oldValue === null && e.value === ''))
				return;
			else
			{
				let row_data = this.grid.data[e.cell.boundRowIndex];

				if (!row_data._edited_cells)
					row_data._edited_cells = {};
	
				row_data._edited_cells[e.cell.header.name] = {
					boundRowIndex: e.cell.boundRowIndex,
					boundColumnIndex: e.cell.boundColumnIndex,
					oldValue: e.cell.formattedValue,
					newValue: e.value
				};

				this.grid.draw();
			}
		});

		this.grid.addEventListener('contextmenu', (e) => {
			if (e.cell && !e.cell.isColumnHeader)
			{
				e.items.push({
					title: 'Delete Row',
					click: async () => {
						let response = await Grape.alerts.confirm({ type: 'danger', message: 'Are you sure you want to delete the ENTIRE row?' });
						if (response)
						{
							let row = this.grid.data[e.cell.boundRowIndex];
							row._deleted = true;
							this.grid.draw();
						}
					}
				});
			}

			if (e.cell && !e.cell.isRowHeader)
			{
				e.items.push({
					title: 'Clear Column',
					click: async () => {
						let response = await Grape.alerts.confirm({ type: 'danger', message: 'Are you sure you want to clear the ENTIRE column?' });
						if (response)
						{
							let col_index = e.cell.boundColumnIndex;
							let header = this.grid.schema[col_index];
			
							for (let row_index = 0; row_index < this.grid.data.length; row_index++)
							{
								let row = this.grid.data[row_index];
								
								if (row[header.name] !== "")
								{
									if (!row._edited_cells)
										row._edited_cells = {};
			
									row._edited_cells[header.name] = {
										boundRowIndex: row_index,
										boundColumnIndex: col_index,
										oldValue: row[header.name],
										newValue: ""
									};
			
									row[header.name] = "";
								}
							}
							this.grid.draw();
						}
					}
				});
			}
		});

 		this.grid.addEventListener('rendercell', (e) => {
			if (e.cell.boundRowIndex > -1 && this.grid.data && this.grid.schema)
			{
				let row_data = this.grid.data[e.cell.boundRowIndex];

				// Cell is empty
				if (e.cell.value === '' || e.cell.value === null || e.cell.value === undefined)
				{
					e.ctx.strokeStyle = 'orange';
					e.ctx.lineWidth = 2;
					e.ctx.strokeRect(e.cell.x, e.cell.y, e.cell.width, e.cell.height);
				}

				// Row is marked as deleted.
				if (row_data && row_data._deleted)
				{
					e.ctx.fillStyle = 'red';
					e.ctx.fillRect(e.cell.x, e.cell.y, e.cell.width, e.cell.height);
				}
				// Cell is edited
				else if (row_data && row_data._edited_cells && row_data._edited_cells[e.cell.header.name])
				{
					e.ctx.fillStyle = 'yellow';
					e.ctx.fillRect(e.cell.x, e.cell.y, e.cell.width, e.cell.height);
				}
			}
		});
	}

	async btn_save_sheet_edits_clicked ()
	{
		let changes = await this.collect_sheet_edits();
		
		this.edited_cells(changes.edited_cells);
		this.deleted_rows(changes.deleted_rows);
		
		await this.save_sheet_edit();
		this.clear_sheet_edit_flags();
	}

	async btn_clear_sheet_edits_clicked ()
	{
		let response = await Grape.alerts.confirm({ type: 'warning', message: 'Are you sure you want to cancel and restore all changes to this sheet?' });
		if (response)
		{
			this.clear_sheet_edit_flags();
			this.update_sheet();
		}
	}

	collect_sheet_edits ()
	{
		let changes = {
			deleted_rows: [],
			edited_cells: []
		};
	
		for (let i = 0; i < this.grid.data.length; i++)
		{
			let row = this.grid.data[i];
			if (row._deleted)
				changes.deleted_rows.push({ boundRowIndex: i, rowData: row });

			if (row._edited_cells)
			{
				for (let header_name in row._edited_cells)
				{
					if (row._edited_cells.hasOwnProperty(header_name))
						changes.edited_cells.push(row._edited_cells[header_name]);
				}
			}
		}
	
		return changes;
	}

	clear_sheet_edit_flags ()
	{
		this.grid.data.forEach(row => {
			if (row._edited_cells)
				delete row._edited_cells;

			if (row._deleted)
				delete row._deleted;
		});

		this.grid.draw();
	}

	async save_sheet_edit ()
	{
		// TODO remove timers here
		if (this.worksheet_status() === 'Importing')
		{
			setTimeout(() => { Grape.alerts.alert({ type: 'warning', message: 'Cannot update values during import!' }); }, 100);
			return;
		}
		else if (this.worksheet_status() === 'Processing')
		{
			setTimeout(() => { Grape.alerts.alert({ type: 'warning', message: 'Cannot update values during processing!' }); }, 100);
			return;
		}
		else if (this.worksheet_status() === 'Completed')
		{
			setTimeout(() => { Grape.alerts.alert({ type: 'warning', message: 'Cannot update values as it has already been added to the system!' }); }, 100);
			return;
		}
		else if (this.worksheet_status() != 'Unbatched')
		{
			setTimeout(() => { Grape.alerts.alert({ type: 'warning', message: 'Can only update values during the Unbatched state!' }); }, 100);
			return;
		}

		if (!this.edited_cells().length && !this.deleted_rows().length)
		{
			Grape.alerts.alert({  type: 'info', message: 'You have not edited any values!' });
			return;
		}

		let cell_upd_promises = this.edited_cells().map((edit) => {
			let row_nr = edit.boundRowIndex;

			if (this.selected_mapping_type() === 'heading-is-names')
				row_nr = row_nr + 1;

			let payload = {
				worksheet_id: this.worksheet_id(),
				row_nr: row_nr,
				cell_nr: edit.boundColumnIndex,
				v: edit.newValue,
				w: edit.newValue
			};

			return Grape.fetches.postJSON('/api/sheets/cell/upsert', payload);
		});

		let row_del_promises = this.deleted_rows().map((del_row) => {
			let row_nr = del_row.boundRowIndex;

			if (this.selected_mapping_type() === 'heading-is-names')
				row_nr = row_nr + 1;

			let payload = {
				worksheet_id: this.worksheet_id(),
				row_nr: row_nr
			};

			return Grape.fetches.postJSON('/api/sheets/row/delete', payload);
		});

		let all_promises = [
			...cell_upd_promises,
			...row_del_promises
		];

		try
		{
			let results = await Promise.all(all_promises);
			let errors = results.filter((res) => res.status !== 'OK');

			if (errors.length)
				Grape.alerts.alert({ type: 'warning', message: 'Some updates failed!' });
			else
			{
				this.update_sheet();
				Grape.alerts.alert({ type: 'success', message: 'Updated successfully!' });
			}

		} catch (error) {
			console.error("Error updating cells or deleting rows/columns", error);
			Grape.alerts.alert({ type: 'error', message: 'Error updating cells or deleting rows/columns.' });
		}
	}

	async btn_process_to_batch_click ()
	{
		this.loading(true);
		this.process_to_batch_clicked(true);

		let pipeline_steps = this.pipeline_steps();
		let pipeline_step_ids = [];
		pipeline_steps.forEach((step) => {
			if (step.selected)
				pipeline_step_ids.push(step.pipeline_step_id);
		});
		
		let params = {};
		for (let [name, $input] of Object.entries(this.ui_param_values()))
			params[name] = ($input)();
		params.pipeline_step_ids = pipeline_step_ids;

		const result = await Grape.fetches.postJSON('/api/etl/worksheet2batch', {
			worksheet_id: this.worksheet_id(),
			mapping_strategy: this.selected_mapping_type(),
			type: this.selected_pipeline().name,
			options: params
		});
		if (result.status == 'OK')
		{
			Grape.alerts.alert({ type: 'success', message: 'Successfuly created records!' });
			this.prev_worksheet_status = this.worksheet_status();
			this.check_worksheet_status();
		}
		else
			Grape.alerts.alert({ type: 'error', message: result.message, title: 'Error' });

		this.loading(false);
	}

	btn_view_batch_click ()
	{
		Grape.navigate(`etl-ui/batch-view?batch_id=${this.batch_id()}`);
	}

	async update_lookups ()
	{
		this.available_pipelines(await Grape.cache.get('PipelineLookup'));
	}

	async update_workbook ()
	{
		clearTimeout(this.refresh_workbook_timeout);

		let result = await Grape.fetches.getJSON('/api/record', {
			table: 'v_workbooks',
			schema: 'sheets',
			filter: [{field: 'workbook_id', value: this.workbook_id(), operand: '='}]
		});

		if (result.status == 'OK' && result.records.length == 1)
		{
			this.workbook(result.records[0]);
			if (result.records[0].sched_status == 'Running' || result.records[0].sched_status == 'New')
			{
				this.refresh_workbook_timeout = setTimeout(async () => {
					await this.update_workbook();
					await this.update_sheets();
					await this.update_sheet();
				}, this.refresh_timeout_duration);
			}
		}
		else
			throw new Error(result.message || result.code);
	}

	async update_sheets ()
	{
		let result = await Grape.fetches.getJSON('/api/record', {
			table: 'v_worksheets',
			schema: 'sheets',
			filter: [{field: 'workbook_id', value: this.workbook_id(), operator: '='}],
			fields: ['name', 'worksheet_id'],
			sortfield: 'worksheet_id'
		});

		if (result.status == 'OK')
			this.worksheets(result.records || []);
		else
		{
			this.worksheets([]);
			throw new Error(result.message || result.code);
		}
	}

	async update_sheet ()
	{
		// Load all entities
		let result = await Grape.fetches.getJSON('/api/record', {
			table: 'v_worksheets',
			schema: 'sheets',
			filter: [{field: 'worksheet_id', value: this.worksheet_id(), operator: '='}]
		});

		if (result.status == 'OK')
		{
			this.worksheet(result.records[0]);
			this.batch_id(result.records[0].batch_id);

			if (this.process_to_batch_clicked() && this.batch_id() != null)
			{
				Grape.navigate(`etl-ui/batch-view?batch_id=${this.batch_id()}`, { auto_process_batch: this.auto_process_batch() });
				return;
			}
			
			await this.update_cells();

			if (!this.worksheet().batch_state)
			{
				if (this.workbook().sched_te)
					this.worksheet_status('Unbatched');
				else
					this.worksheet_status('Importing');
			}
			else if (this.worksheet().batch_state == 'ReadyForProcessing')
				this.worksheet_status('ReadyForProcessing');
			else
				this.worksheet_status(this.worksheet().batch_state);

			this.check_worksheet_status();
		}
		else
			throw new Error(result.message || result.code);
	}

	async update_cells ()
	{
		let result = await Grape.fetches.getJSON('/api/record', {
			table: 'v_cell_value_array',
			schema: 'sheets',
			filter: [{field: 'worksheet_id', value: this.worksheet_id(), operator: '='}]
		});

		if (result.status == 'OK')
			this.grid.data = result.records[0].data;
		else
		{
			this.records([]);
			throw new Error(result.message || result.code);
		}

		if (this.selected_mapping_type() === 'heading-is-names')
		{
			let header_row = this.grid.data[0];
			let new_schema = [];
		
			Object.keys(header_row).forEach((key, index) => { new_schema.push({ name: key, title: header_row[key] || `Column ${index + 1}` }); });
		
			this.grid.schema = new_schema;
			this.grid.data = this.grid.data.slice(1);
		}
		else if (this.selected_mapping_type() === 'map')
			if (this.grid.data && this.grid.data.length)
				this.grid.schema = Object.keys(this.grid.data[0]).map(key => ({ name: key, title: key }));

		this.grid.draw();
	}

	check_worksheet_status ()
	{
		clearTimeout(this.refresh_timeout);
		let curr_status = this.worksheet_status();
		if (curr_status == null || curr_status == 'Importing' || curr_status === 'Processing' || this.prev_worksheet_status == curr_status)
		{
			this.refresh_timeout = setTimeout(async () => {
				await this.update_workbook();
				await this.update_sheets();
				await this.update_sheet();
			}, this.refresh_timeout_duration);
		}
		else if ((this.prev_worksheet_status === 'ReadyForProcessing' || this.prev_worksheet_status === 'Processing') && this.worksheet() && this.worksheet().bp_sched_status && this.worksheet().bp_sched_status === 'Completed')
			Grape.alerts.alert({ type: 'info', message: 'Batch Processing Completed!' });
	}

	btn_workbooks_click ()
	{
		Grape.navigate(`etl-ui/workbook-list`);
	}
}

class WorkbooksViewPageClass
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new WorkbooksViewPageViewModel(this);
		this.viewModel.element = element;
		this.name = 'WorkbooksViewPageClass';

		this.viewModel.workbook_id(bindings.workbook_id);
	}

	init ()
	{
		document.title = 'Workbook';
	}

	async updateData ()
	{
		this.viewModel.loading(true);

		if (this.viewModel.workbook_id() == null || this.viewModel.workbook_id() == 'null')
		{
			Grape.navigate(`etl-ui/workbook-list`);
			Grape.alerts.alert({ type: 'info', message: 'Could not find the workbook! It might still be starting the processing! Try navigating to it again in a minute.' });
			return;
		}

		await this.viewModel.update_workbook();
		await this.viewModel.update_lookups();
		await this.viewModel.update_sheets();

		this.viewModel.loading(false);
	}

	async teardown ()
	{
		clearTimeout(this.viewModel.refresh_timeout);
		clearTimeout(this.viewModel.refresh_workbook_timeout);
	}
}

export default {
	route: '[/]etl-ui/workbook-view',
	page_class: WorkbooksViewPageClass,
	template: template
};
