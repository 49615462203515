import template from './BatchListPage.html';
import BatchListModel from './BatchListModel.js';

class BatchListPageViewModel
{
	constructor (page)
	{
		this.page = page;
		this.el = page.element;
		this.loading = ko.observable(false);
		this.model = new BatchListModel();
	}

	btn_refresh_click ()
	{
		this.page.updateData();
	}

	btn_workbook_list_click ()
	{
		Grape.navigate(`etl-ui/workbook-list`);
	}
}

class BatchListPageClass
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new BatchListPageViewModel(this);
		this.viewModel.element = element;
		this.name = 'BatchListPageClass';
	}

	init ()
	{
		document.title = 'Batches';
	}

	async updateData ()
	{
		this.viewModel.loading(true);
		this.viewModel.model.load();
		this.viewModel.loading(false);
	}
}

export default {
	route: '[/]etl-ui/batch-list',
	page_class: BatchListPageClass,
	template: template
};
