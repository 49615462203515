import template from './WorkbookList.html';

class WorkbooksListPageViewModel
{
	constructor (page)
	{
		this.page = page;
		this.loading = ko.observable(false);
		this.records = ko.observableArray([]);
		this.selected_workbook = ko.observable(null);
		this.selected_worksheets = ko.observableArray([]);
	}

	async btn_upload_click ()
	{
		let input_element = document.body.getElementsByClassName('file-upload-input')[0];
		let form_data = new FormData();

		for (let file of input_element.files)
			form_data.append('file', file);

		await fetch('/api/sheets/workbook-upload', { method: 'POST', body: form_data });
		this.page.reload_records();
	}

	async btn_refresh_click ()
	{
		await this.page.reload_records();

		if (this.selected_workbook())
			this.load_worksheets(this.selected_workbook());
	}

	btn_batch_list_click ()
	{
		Grape.navigate(`etl-ui/batch-list`);
	}

	view_worksheets (workbook)
	{
		this.selected_workbook(workbook);
		this.load_worksheets(workbook);
	}

	async load_worksheets (workbook)
	{
		let result = await Grape.fetches.getJSON('/api/record', {
			table: 'v_worksheets',
			schema: 'sheets',
			filter: [{field: 'workbook_id', value: workbook.workbook_id, operand: '='}],
			sortfield: 'date_inserted',
			sortorder: 'DESC'
		})

		if (result.status === 'OK')
			this.selected_worksheets(result.records || []);
		else
		{
			this.selected_worksheets([]);
			Grape.alerts.alert({ type: 'error', message: result.message || result.code });
		}
	}

	async delete_workbook (workbook)
	{
		let confirm = await Grape.alerts.confirm({ message: 'Are you sure you want to delete this workbook?', type: 'warning' });

		if (!confirm)
			return;

		await fetch(`/api/sheets/workbook/?workbook_id=${workbook.workbook_id}`, {
			method: 'DELETE'
		});

		this.page.reload_records();
	}
}

class WorkbooksListPageClass
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new WorkbooksListPageViewModel(this);
		this.name = 'WorkbooksListPageClass';
		this.refresh_timeout = null;
		this.refresh_timeout_duration = 2000;
		this.max_reloads = 0;
	}

	init ()
	{
		document.title = 'Workbooks & Worksheets';
	}

	async reload_records ()
	{
		clearTimeout(this.refresh_timeout);
		this.viewModel.loading(true);
		let result = await Grape.fetches.getJSON('/api/record', {
			table: 'v_workbooks',
			schema: 'sheets',
			sortfield: 'date_inserted',
			sortorder: 'DESC',
			limit: 100
		});

		// Auto-reload if there are any pending batches (sched_te === null)
		let has_pending = false;

		result.records.forEach((rec) => {
			if (rec.sched_te === null) has_pending = true;
		});

		if (has_pending && this.max_reloads++ < 10)
			this.refresh_timeout = setTimeout(async () => { await this.reload_records(); }, this.refresh_timeout_duration);

		if (result.status === 'OK')
			this.viewModel.records(result.records || []);
		else
		{
			this.viewModel.records([]);
			throw new Error(result.message || result.code);
		}
		this.viewModel.loading(false);
	}

	async updateData ()
	{
		this.max_reloads = 0;
		this.viewModel.loading(true);
		await this.reload_records();
		this.viewModel.loading(false);
	}

	async teardown ()
	{
		clearTimeout(this.refresh_timeout);
	}
}

export default {
	route: '[/]etl-ui/workbook-list',
	page_class: WorkbooksListPageClass,
	template: template
};
