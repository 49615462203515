
import Pipelines from './Pipelines/Pipelines.js';
import WorkbookList from './WorkbookList/WorkbookList.js';
import WorkbookView from './WorkbookView/WorkbookView.js';
import BatchView from './BatchView/BatchView.js';
import RecordList from './records/RecordList.js';
import BatchList from './batches/BatchListPage.js';
import HTTPCalls from './HTTPCalls/HTTPCallList.js';
import HTTPCallTemplates from './HTTPCallTemplates/HTTPCallTemplateList.js';

export default [
	Pipelines,
	RecordList,
	WorkbookList,
	WorkbookView,
	BatchList,
	BatchView,
	HTTPCalls,
	HTTPCallTemplates
];
